define("discourse/plugins/discourse-solved/discourse/connectors/after-topic-status/solved-status", ["exports", "@glimmer/component", "@ember/service", "truth-helpers", "discourse/helpers/d-icon", "discourse-i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _truthHelpers, _dIcon, _discourseI18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class SolvedStatus extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{~#if
          (or
            @outletArgs.topic.has_accepted_answer @outletArgs.topic.accepted_answer
          )
        ~}}
          <span
            title={{i18n "topic_statuses.solved.help"}}
            class="topic-status"
          >{{icon "far-square-check"}}</span>
        {{~else if @outletArgs.topic.can_have_answer~}}
          <span
            title={{i18n "solved.has_no_accepted_answer"}}
            class="topic-status"
          >{{icon "far-square"}}</span>
        {{~/if~}}
      
    */
    {
      "id": "iPL5W1VH",
      "block": "[[[41,[28,[32,0],[[30,1,[\"topic\",\"has_accepted_answer\"]],[30,1,[\"topic\",\"accepted_answer\"]]],null],[[[10,1],[15,\"title\",[28,[32,1],[\"topic_statuses.solved.help\"],null]],[14,0,\"topic-status\"],[12],[1,[28,[32,2],[\"far-square-check\"],null]],[13]],[]],[[[41,[30,1,[\"topic\",\"can_have_answer\"]],[[[10,1],[15,\"title\",[28,[32,1],[\"solved.has_no_accepted_answer\"],null]],[14,0,\"topic-status\"],[12],[1,[28,[32,2],[\"far-square\"],null]],[13]],[]],null]],[]]]],[\"@outletArgs\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-solved/discourse/connectors/after-topic-status/solved-status.js",
      "scope": () => [_truthHelpers.or, _discourseI18n.i18n, _dIcon.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = SolvedStatus;
});